// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-jsx": () => import("./../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-our-process-jsx": () => import("./../src/templates/our-process.jsx" /* webpackChunkName: "component---src-templates-our-process-jsx" */),
  "component---src-templates-project-template-project-template-jsx": () => import("./../src/templates/project-template/project-template.jsx" /* webpackChunkName: "component---src-templates-project-template-project-template-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */),
  "component---src-templates-service-template-service-template-jsx": () => import("./../src/templates/service-template/service-template.jsx" /* webpackChunkName: "component---src-templates-service-template-service-template-jsx" */),
  "component---src-templates-services-jsx": () => import("./../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */)
}

